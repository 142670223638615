import ETH from './eth.png'
import BNB from './bnb.png'
import SCAI from './scai.png'

export const CURRENCY = {
  ETH,
  BNB,
  SCAI,
  
  // ------------

  1: ETH,
  3: ETH,
  4: ETH,
  5: ETH,
  42: ETH,
  56: BNB,
  97: BNB,
  
  34: SCAI, // https://securechain.ai/
}
